export default {
  mounted () {
    const linkToggle = document.querySelectorAll('.accordion__item-button')
    for (let i = 0; i < linkToggle.length; i++) {
      linkToggle[i].addEventListener('click', function (event) {
        if (!linkToggle[i].parentNode.classList.contains('accordion__item_active')) {
          const container = linkToggle[i].parentNode.querySelectorAll('.accordion__item-content-inner')[0]
          const containerOuter = linkToggle[i].parentNode.querySelectorAll('.accordion__item-content')[0]
          containerOuter.style.height = container.clientHeight + 'px'

          const otherActiveElements = linkToggle[i].parentNode.parentNode.parentNode.querySelectorAll('.accordion__item_active')
          for (let j = 0; j < otherActiveElements.length; j++) {
            const container = otherActiveElements[j].querySelectorAll('.accordion__item-content-inner')[0]
            const containerOuter = otherActiveElements[j].querySelectorAll('.accordion__item-content')[0]
            containerOuter.style.height = container.clientHeight + 'px'
            otherActiveElements[j].classList.remove('accordion__item_active')

            setTimeout(() => {
              containerOuter.style.height = 0 + 'px'
            }, 50)
          }

          linkToggle[i].parentNode.classList.add('accordion__item_active')
        } else {
          const container = linkToggle[i].parentNode.querySelectorAll('.accordion__item-content-inner')[0]
          const containerOuter = linkToggle[i].parentNode.querySelectorAll('.accordion__item-content')[0]
          containerOuter.style.height = container.clientHeight + 'px'
          linkToggle[i].parentNode.classList.remove('accordion__item_active')
          setTimeout(() => {
            containerOuter.style.height = 0 + 'px'
          }, 50)
        }
      })
    }
  }
}
