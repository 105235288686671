<template lang="pug">
  .wrapper
    main
      .inside-content
        .container
          h1.page-title О сервисе

          .accordion
            .accordion__item.accordion__item_white.accordion__item_active
              button.accordion__item-button(type="button") Нужно ли быть проектировщиком, чтобы сконструировать систему отопления в коттедже?
              .accordion__item-content
                .accordion__item-content-inner.active
                  p Теперь не нужно. Мы создали свой сервис, чтобы любой человек, не обладающий специальными знаниями, мог это сделать.<br> И сделать «по всем правилам».

            .accordion__item.accordion__item_white
              button.accordion__item-button(type="button") Что значит «по всем правилам»?
              .accordion__item-content
                .accordion__item-content-inner
                  p В расчетах мы используем методики, приведенные в классических учебниках:
                  p – Малявина Е.Г. Теплопотери здания: справочное пособие /Е.Г. Малявина. - М.: АВОК-ПРЕСС, 2007;
                  p – Фокин К.Ф. Строительная теплотехника ограждающих частей здания / Под ред. Ю.А. Табунщикова, В.Г. Гагарина. - М.: АВОК-ПРЕСС, 2006;
                  p – Богословский В.Н. Строительная теплофизика. - М.: Высшая школа, 1982;
                  p – Богословский В.Н., Сканави А.Н. - Отопление: Учеб. для вузов. - М.: Стройиздат, 1991;
                  p – Внутренние санитарно-технические устройства. В 3 ч. Ч. I. Отопление / В.Н. Богословский, Б.А. Крупнов, А.Н. Сканави и др.: Под ред. И.Г. Староверова и Ю.И. Шиллера. М.: Стройиздат, 1990;
                  p – Андреевский А.К. Отопление: Учебное пособие для вузов по спец. 1208 «Теплогазоснабжение и вентиляция» / Под ред. М.И. Курпана. - Мн.: Выш. Школа, 1982.

            .accordion__item.accordion__item_white
              button.accordion__item-button(type="button") Результаты ваших расчетов мы проверяем на соответствие требованиям
              .accordion__item-content
                .accordion__item-content-inner
                  p СП 50.13330.2012 Тепловая защита зданий. Актуализированная редакция СНиП 23-02-2003;
                  p DIN EN 1264 Система обогрева поверхностей.
                  p Отчеты оформляем по ГОСТ Р 21.1101-2013 «Система проектной документации для строительства (СПДС).
                  p Основные требования к проектной и рабочей документации».

            .accordion__item.accordion__item_white
              button.accordion__item-button(type="button") Кому это нужно?
              .accordion__item-content
                .accordion__item-content-inner
                  p В первую очередь профессиональным монтажникам, т. е. тем, кто зарабатывает на жизнь монтажом систем отопления.
                  p И тому, кто строит дом своими руками.
                  p И проектировщикам — чтобы быстро «просчитать» простой объект.
                  p И менеджерам по продажам оборудования для систем отопления.
                  p И студентам соответствующих специальностей.
            .accordion__item.accordion__item_white
              button.accordion__item-button(type="button") Как с нами связаться?
              .accordion__item-content
                .accordion__item-content-inner
                  p
                    a.mailto(href="mailto:support@heatingcalculator.online") support@heatingcalculator.online

</template>

<script>
import navigationMixin from 'mixins/navigation'
import accordionMixin from 'mixins/accordion'

export default {
  name: 'About',
  mixins: [navigationMixin, accordionMixin],
  metaInfo: {
    title: 'О сервисе Спроектируй.рф: методики и результаты расчетов',
    meta: [
      {
        name: 'description',
        content: 'Мы создали свой сервис, чтобы любой человек, не обладающий специальными знаниями, мог сделать расчет отопления по «по всем правилам»'
      }
    ]
  },
  mounted () {
  }
}
</script>
